/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const LogRocket = require("logrocket");

exports.onClientEntry = () => {
  if (process.env.NODE_ENV !== 'development') LogRocket.init('acara-main/acara');

}